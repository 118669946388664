export interface ChatConfig {
  feedId: string
  initialMessage: string
  subdomain: string
  title: string
}

export const chatConfigs: Record<string, ChatConfig> = {
  default: {
    feedId: "bdt_test_data_prod_1yr",
    initialMessage: "Hello! I'm your AI assistant. Welcome to the Black Dave Token Chat AI. Ask me about topics that might have been discussed in the chat",
    subdomain: "bdt-demo",
    title: "Black Dave Token Chat AI"
  },
  tea: {
    feedId: "tea_test_data",
    initialMessage: "Hello! I'm your unofficial Token Engineering Academy Archive Chat AI assistant. I can help you with Token Engineering Academy Archive Chat. Ask me about topics discussed in the chat and I'll respond with information from the chat conversations",
    subdomain: "tea",
    title: "Unofficial Token Engineering Academy Archive Chat"
  }
}

// Helper to validate config exists
export const getConfigByKey = (key: string): ChatConfig => {
  if (!chatConfigs[key]) {
    console.warn(`Config not found for ${key}, using default`)
    return chatConfigs.default
  }
  return chatConfigs[key]
} 